<script>
    import { POST_openHongbao, POST_chatRoomNickname } from "@/api/beijing28.js"

    export default {
    name: 'ChatRoom',
    components: {
        Header: () => import('components/base/Header'),
        Billboard: () => import('components/pc28/Billboard'),
        ChatRoomTuhaoAnimation: () => import('components/pc28/ChatRoomTuhaoAnimation'),
    },
    data() {
        return {
            message:'',
            header: {
                mainTitle: getWord('chatroom2'),
            },

            aside:{
                conditions: false,
                limit:false,
                nickname:false,
            },

            user:{
                level:{
                    value:0
                },
            },
            nickname:null,
            hongbao:{
                getAmount:null,
                id:null,
                state:null,
                item:null,
            },
            talkFrequenceTimer:null,
            nicknameComfirming:false,
            shortcutPanel:false,
            shortcutTitle:{
                text:{
                    show:true,
                    title: getWord('phrase'),
                },
                image:{
                    show:false,
                    title:'GIF',
                }
            },

            shortcutImage:[
                {
                    url:require('@@/assets/images/chatroom/gif/shortcuts/1.gif'),
                },
                {
                    url:require('@@/assets/images/chatroom/gif/shortcuts/3.gif'),
                },
                {
                    url:require('@@/assets/images/chatroom/gif/shortcuts/4.gif'),
                },
                {
                    url:require('@@/assets/images/chatroom/gif/shortcuts/5.gif'),
                },
                {
                    url:require('@@/assets/images/chatroom/gif/shortcuts/6.gif'),
                },
                {
                    url:require('@@/assets/images/chatroom/gif/shortcuts/11.gif'),
                },
                {
                    url:require('@@/assets/images/chatroom/gif/shortcuts/12.gif'),
                },
                {
                    url:require('@@/assets/images/chatroom/gif/shortcuts/13.gif'),
                },
                {
                    url:require('@@/assets/images/chatroom/gif/shortcuts/14.gif'),
                },
                {
                    url:require('@@/assets/images/chatroom/gif/shortcuts/15.gif'),
                },
                {
                    url:require('@@/assets/images/chatroom/gif/shortcuts/16.gif'),
                },
                {
                    url:require('@@/assets/images/chatroom/gif/shortcuts/17.gif'),
                },
                {
                    url:require('@@/assets/images/chatroom/gif/shortcuts/18.gif'),
                },
                {
                    url:require('@@/assets/images/chatroom/gif/shortcuts/19.gif'),
                },
                {
                    url:require('@@/assets/images/chatroom/gif/shortcuts/20.gif'),
                },
                {
                    url:require('@@/assets/images/chatroom/gif/shortcuts/23.gif'),
                },
                {
                    url:require('@@/assets/images/chatroom/gif/shortcuts/26.gif'),
                },
                {
                    url:require('@@/assets/images/chatroom/gif/shortcuts/27.gif'),
                },
                {
                    url:require('@@/assets/images/chatroom/gif/shortcuts/28.gif'),
                },
                {
                    url:require('@@/assets/images/chatroom/gif/shortcuts/30.gif'),
                },
                {
                    url:require('@@/assets/images/chatroom/gif/shortcuts/31.gif'),
                },
                {
                    url:require('@@/assets/images/chatroom/gif/shortcuts/32.gif'),
                },
                {
                    url:require('@@/assets/images/chatroom/gif/shortcuts/33.gif'),
                },
                {
                    url:require('@@/assets/images/chatroom/gif/shortcuts/38.gif'),
                },
                {
                    url:require('@@/assets/images/chatroom/gif/shortcuts/39.gif'),
                },
                {
                    url:require('@@/assets/images/chatroom/gif/shortcuts/40.gif'),
                },
                {
                    url:require('@@/assets/images/chatroom/gif/shortcuts/43.gif'),
                },
                {
                    url:require('@@/assets/images/chatroom/gif/shortcuts/45.gif'),
                },
                {
                    url:require('@@/assets/images/chatroom/gif/shortcuts/46.gif'),
                },
            ],
            shortcutText:[
                {
                    value:getWord('chatroom_shortcut_phrase1'),
                },
                {
                    value:getWord('chatroom_shortcut_phrase2'),
                },
                {
                    value:getWord('chatroom_shortcut_phrase4'),
                },
                {
                    value:getWord('chatroom_shortcut_phrase1'),
                },
                {
                    value:getWord('chatroom_shortcut_phrase6'),
                },
                {
                    value:getWord('chatroom_shortcut_phrase3'),
                },
                {
                    value:getWord('chatroom_shortcut_phrase5'),
                },
                {
                    value:getWord('chatroom_shortcut_phrase7'),
                },
                {
                    value:getWord('chatroom_shortcut_phrase9'),
                },
                {
                    value:getWord('chatroom_shortcut_phrase10'),
                },
                {
                    value:getWord('chatroom_shortcut_phrase11'),
                },
                {
                    value:getWord('chatroom_shortcut_phrase12'),
                },
                {
                    value:getWord('chatroom_shortcut_phrase13'),
                },
                {
                    value:getWord('chatroom_shortcut_phrase14'),
                },
                {
                    value:getWord('chatroom_shortcut_phrase15'),
                },
                {
                    value:getWord('chatroom_shortcut_phrase6'),
                },
                {
                    value:getWord('chatroom_shortcut_phrase3'),
                },
                {
                    value:getWord('chatroom_shortcut_phrase5'),
                },
                {
                    value:getWord('chatroom_shortcut_phrase7'),
                },
                {
                    value:getWord('chatroom_shortcut_phrase9'),
                },
            ],
        }
    },
    props: [
        'game',
    ],
    inject: [
        'sendMessage',
        'scrollEnd',
        'toggleChatRoom',
        'messageFilter',
    ],
    methods: {
        chatroomOpenTime(lineNumber){
            if (JSON.parse(this.chatroomSettings.opend_time_range).length<lineNumber+1) {
                return '';
            }else {
                var startTimeStamp=JSON.parse(this.chatroomSettings.opend_time_range)[lineNumber][0]*1000;
                var endTimeStamp=JSON.parse(this.chatroomSettings.opend_time_range)[lineNumber][1]*1000;

                var startHours=new Date(startTimeStamp).getHours()>10?new Date(startTimeStamp).getHours():'0'+new Date(startTimeStamp).getHours()
                var startMinutes=new Date(startTimeStamp).getMinutes()>10?new Date(startTimeStamp).getMinutes():'0'+new Date(startTimeStamp).getMinutes()
                var startSeconds=new Date(startTimeStamp).getSeconds()>10?new Date(startTimeStamp).getSeconds():'0'+new Date(startTimeStamp).getSeconds()

                var endHours=new Date(endTimeStamp).getHours()>10?new Date(endTimeStamp).getHours():'0'+new Date(endTimeStamp).getHours()
                var endMinutes=new Date(endTimeStamp).getMinutes()>10?new Date(endTimeStamp).getMinutes():'0'+new Date(endTimeStamp).getMinutes()
                var endSeconds=new Date(endTimeStamp).getSeconds()>10?new Date(endTimeStamp).getSeconds():'0'+new Date(endTimeStamp).getSeconds()

                return startHours+':'+startMinutes+':'+startSeconds+'-'+endHours+':'+endMinutes+':'+endSeconds
            }
        },
        switchShortcut(type){
            Object.keys(this.shortcutTitle).forEach(item=>{
                this.shortcutTitle[item].show=false;
            })
            this.shortcutTitle[type].show=true;
        },
        sendShortcut(data) {
            this.message={
                'type':data.type,
                'id':data.id,
                'value':data.value
            }
            this.send();
        },
        send(payload){
            var that=this;
            var checkCondition=null;
            var msg=(typeof this.message === 'object' )
                    ?this.message
                    :this.messageFilter({'msg':this.message})
            if (!this.message) {
                app.Mint.Toast(getWord('fill_content'));
                return false;
            }

            if ((this.talkFrequenceTimer && this.chatroomSettings.talk_limit)) {
                app.Mint.Toast(getWord('chatroom_info4'))
                this.message='';
                return false;
            }else {
                that.talkFrequenceTimer=setTimeout(function(){
                    that.talkFrequenceTimer=null;
                },this.chatroomSettings.talk_limit*1000)
            }

            // if ((JSON.parse(that.chatroomSettings.opend_time_range)['0']['0'] < Math.round(new Date().getTime()/1000)) && (Math.round(new Date().getTime()/1000) < JSON.parse(that.chatroomSettings.opend_time_range)['0']['1'])) {
            //     this.aside.limit=true;
            //     this.message='';
            //     return false;
            // }

            // if (this.chatroomSettings.talk_conditions.level.status===true && this.chatroomSettings.talk_conditions.level.values.indexOf(this.user.level.value)===0) {
            //     checkCondition=false
            // }

            // if (!checkCondition) {
            //     this.aside.conditions=true;
            //     this.message='';
            //     return false;
            // }

            this.sendMessage(msg,function(){
                that.scrollEnd(that.$refs.chatRoomHgroup);
            });
            this.message='';
            this.shortcutPanelHide()
        },
        close(from){
            if (from==='nickname' && this.nicknameComfirming) {
                return false;
            }
            if (from==='nickname' && !this.nicknameComfirming) {
                this.toggleChatRoom();
            }
            this.aside.conditions=false;
            this.aside.limit=false;
            this.aside.nickname=false;

            this.hongbao.state=null;
        },
        closeConditions(item) {
            this.$store.commit('UPDATE_chatRoomNoticeState',Object.assign(item,{'state':false}))
            this.$store.commit('CLEAN_chatRoomMessageByConditions',item)
        },
        comfirm(nickname){
            var that=this;
            var filteredName=this.messageFilter({'msg':nickname});

            if (this.nicknameComfirming) {
                console.log('intercepted.')
                return false;
            }

            var nicknamePattern = /^\S{3,10}$/;
            if (!nickname) {
                app.Mint.Toast(getWord('chatroom_rules4',{
                    '$1':'3',
                    '$2':'10',
                }));
                return false;
            }
            if (!nickname.match(nicknamePattern)) {
                app.Mint.Toast(getWord('chatroom_rules4',{
                    '$1':'3',
                    '$2':'10',
                }));
                return false;
            }else if (filteredName.indexOf('*')>-1) {
                app.Mint.Toast(getWord('chatroom_rules7'));
                return false;
            }

            console.log('nickname checked, ready for communicate to server.')
            this.nicknameComfirming=true;
            POST_chatRoomNickname(nickname).then(function(result){
                if (result.data.code === 'SUCCESS') {
                    localStorage.setItem('user',JSON.stringify(Object.assign({
                            'chatroomNickname':nickname
                        },JSON.parse(localStorage.getItem('user'))))
                    )
                    that.$store.commit('UPDATE_chatRoomNickname',nickname);
                    
                    that.aside.nickname=false;
                    that.nicknameComfirming=false;
                }else {
                    setTimeout(function(){
                        that.nicknameComfirming=false;
                        that.comfirm(nickname)
                    },1000);
                }
            })
            
        },
        openHongbao(id,item,repeatString) {
            var that=this;
            POST_openHongbao(id,repeatString).then(function(result){
                if(result.data.code === 'SUCCESS'){
                    that.hongbao.getAmount=result.data.result;
                    if (repeatString) {
                        that.hongbao.state=2
                    }else if (that.hongbao.state===1) {
                        that.hongbao.state=2;
                    }else {
                        that.hongbao.state=1
                    }
                    that.$store.commit('UPDATE_hongbaoState',Object.assign(item,{
                        'value':result.data.result,
                        'state':repeatString?3:that.hongbao.state
                    }));
                }else if (result.data.code === 'FAIL') {
                    that.hongbao.state=3;
                    that.$store.commit('UPDATE_hongbaoState',Object.assign(item,{
                        'state':that.hongbao.state,
                        'error':result.data.msg,
                    }));
                }
            })
        },
        getHongbaoID(item,repeatString) {
            this.hongbao.id=item.id;
            this.hongbao.item=item;
            this.repeatString=repeatString;
            if (item.state===3){
                if (item.repeatString) {
                    if (!item.value) {
                        this.hongbao.state=3;
                        return false;
                    }else {
                        this.hongbao.state=2;
                        return false;
                    }
                }else {
                    this.hongbao.state=3;
                    return false;
                }
            }
            if (item.repeatString&&!repeatString) {
                this.message=item.repeatString;
            }else {                
                if (item.value) {
                    this.hongbao.state=2;
                    return false;
                }
                if (repeatString) {
                    this.openHongbao(item.id,item,repeatString);
                }else {
                    this.hongbao.state=1
                }
            }
        },
        getAmount(){
            this.hongbao.getAmount=this.hongbao.item.value;
            return this.hongbao.item.value
        },
        chatInputFocus(){
            this.shortcutPanel=true;
        },
        shortcutPanelHide(){
            this.shortcutPanel=false;
        },
        keyboardSwitch(){
            var that=this;
            this.shortcutPanelHide();
            var iOS = navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
            if (!iOS) {
                this.$refs.chatInput.focus();
            }
        },
        checkStatus(){
            if (this.chatroomSettings.status!=='1') {
                return false;
            }
        },
        onlineBoardcast(){
            var that=this;

            POST_chatRoomNickname(this.userInfo.talkroom_nick_name).then(function(result){
                if (result.data.code === 'SUCCESS') {
                    that.$store.commit('UPDATE_tuhaoAnimationTimer',{});
                }else {
                    setTimeout(function(){
                        that.nicknameComfirming=false;
                        that.onlineBoardcast(that.userInfo.talkroom_nick_name)
                    },1000);
                }
            })
        },
        renderNickname(value) {
            return value&&value.slice(0,2)+value.slice(2).replace(/\S/g,'*')
        },
        checkTime(item,index){
            if (index===0) {
                return true;
            }else if ( (item.create_time-this.chatRoomMessages[index-1].create_time)>1000*60*1 ) {                
                return true;
            }else {
                return false;
            }
        }
    },    
    provide:function(){
        
    },
    mounted() {
        this.CustomScroll(this.$refs.chatRoomHgroup.id);

        if (!this.userInfo.talkroom_nick_name) {
            console.log('first login chatroom')
            this.aside.nickname=true;
        }
        this.$store.dispatch('GET_chatRoomHongbaos',{});
        this.$store.dispatch('GET_onlineUsers',{});

        if (this.userInfo&&this.userInfo.talkroom_nick_name&&this.userInfo.grade_id>=this.chatroomSettings.in_effect&&!this.tuhaoAnimationTimer) {
            this.onlineBoardcast();
        }
    },
    computed:{
        tuhaoAnimationTimer(){
            return this.$store.state.tuhaoAnimationTimer;
        },
        chatRoomMessages(){
            return this.$store.state.chatRoomMessages||[];
        },
        chatroomNotices(){
            return this.$store.state.chatRoomNotices;
        },
        chatroomSettings(){
            return this.$store.state.chatroomSettings;
        },
        chatRoomHongbaos(){
            return this.$store.state.chatRoomHongbaos;  
        },
        onlineUsers(){
            return this.$store.state.onlineUsers;
        },
        userInfo(){
            return this.$store.state.userInfo;
        },
        chatroomNickname(){
            return localStorage.getItem('user')&&JSON.parse(localStorage.getItem('user')).chatroomNickname
        },
        username(){
            return localStorage.getItem('user')&&JSON.parse(localStorage.getItem('user')).username
        },
        chatroomOpenDate(){
            var startTimeStamp=JSON.parse(this.chatroomSettings.open_date_range)[0]*1000;
            var endTimeStamp=JSON.parse(this.chatroomSettings.open_date_range)[1]*1000;

            var startFullYear=new Date(startTimeStamp).getFullYear()
            var startMonth=new Date(startTimeStamp).getMonth()+1>10?new Date(startTimeStamp).getMonth()+1:'0'+(new Date(startTimeStamp).getMonth()+1)
            var startDate=new Date(startTimeStamp).getDate()>10?new Date(startTimeStamp).getDate():'0'+new Date(startTimeStamp).getDate()

            var endFullYear=new Date(endTimeStamp).getFullYear()
            var endMonth=new Date(endTimeStamp).getMonth()+1>10?new Date(endTimeStamp).getMonth()+1:'0'+(new Date(endTimeStamp).getMonth()+1)
            var endDate=new Date(endTimeStamp).getDate()>10?new Date(endTimeStamp).getDate():'0'+new Date(endTimeStamp).getDate()      

            return startFullYear+'-'+startMonth+'-'+startDate+getWord('to')+endFullYear+'-'+endMonth+'-'+endDate      
        },
    },
    watch:{
        
    },
    beforeDestroy(){
        
    },
};
</script>
<template>
    <div id="chat-room" :class="_TEMPLATE">
        <template v-if="_TEMPLATE==='template-2'||_TEMPLATE==='template-3'">
            <Header 
                :mainTitle="header.mainTitle" 
                :backClose=true
                :chatroomOpen=true                
            />
        </template>
        <hgroup ref="chatRoomHgroup" id="chat-room-hgroup" @click="shortcutPanelHide()">
            <template v-for="(item,index) in chatRoomMessages">
                <time v-if="checkTime(item,index)">{{ item.create_time?new Date(item.create_time).toLocaleString():new Date().toLocaleString() }}</time>
                <h4 v-if="item.type==='all'">
                    <img class="avatar" :src="item.avatar" />
                    <div>
                        <b> {{item.grade}} {{ renderNickname(item.nickname) }}</b>
                        <span>{{item.msg}}</span>
                    </div>
                </h4>
                <h4 v-if="item.type==='admin'" class="red">
                    <img class="avatar" :src="item.avatar" />
                    <div>
                        <b> {{item.grade}} {{item.nickname}} {{item.time}}</b>
                        <span>{{item.msg}}</span>
                    </div>
                </h4>
                <h4 v-if="item.type==='plan'">
                    <img class="avatar" :src="item.avatar" />
                    <div>                        
                        <b> {{item.grade}} {{item.nickname}} {{item.time}}</b>
                        <span class="plan">
                            <label>{{item.gametype}}</label>
                            <i>{{item.msg}}</i>
                        </span>
                    </div>
                </h4>
                <h5 v-if="item.type==='self'">
                    <img class="avatar" :src="item.avatar" />
                    <div>
                        <b> {{item.grade}} {{ renderNickname(item.nickname) }}</b>
                        <span>{{item.msg}}</span>
                    </div>
                </h5>
                <h4 v-if="item.type==='hongbao'" @click="getHongbaoID(item)">
                    <img class="avatar" :src="item.avatar" />
                    <div>
                        <b class="red"> {{item.grade}} {{item.admin_nick_name}}</b>
                        <span class="hongbao" :class="{'open':item.state&&item.state!==null, 'repeat-string':item.repeatString }">
                            <b>{{item.title}}</b>
                            <i v-if="item.repeatString">{{ getWord('password_red_envelope') }}</i>
                        </span>
                    </div>
                    <ul>
                        <li v-for="_item in item.openList">
                            <template v-if="username===_item.username">
                                <img :src="require('@@/assets/images/chatroom/bg/popup-hongbao.png')" />{{ getWord(['you2', 'obtained3']) }}
                            </template>
                            <template v-else>
                                <i>{{_item.username}}</i>{{ getWord('obtained3') }}
                            </template>
                            <i>￥{{_item.bonus_money}}</i></li>
                    </ul>
                </h4>
                <h6 v-if="item.type==='enter'">
                    <!-- <img class="tuhao" :src="require('@@/assets/images/chatroom/gif/tuhao.gif')" v-if="!item.tuhao" /> -->
                    <ChatRoomTuhaoAnimation v-if="item.grade_id>=chatroomSettings.talk_conditions.level.value" />
                    <span>{{ getWord('chatroom_info',{
                        '$1':item.name
                    }) }}</span>
                </h6>
                <h4 v-if="item.type==='SHORTCUT_IMAGE'">
                    <img class="avatar" :src="item.avatar" />
                    <div>
                        <b> {{item.grade}} {{item.nickname}} {{item.time}}</b>
                        <img :src="shortcutImage[item.msg].url" />
                    </div>
                </h4>
                <h4 v-if="item.type==='SHORTCUT_TEXT'">
                    <img class="avatar" :src="item.avatar" />
                    <div>
                        <b> {{item.grade}} {{item.nickname}} {{item.time}}</b>
                        <span>{{item.msg}}</span>
                    </div>
                </h4>
                <h5 v-if="item.type==='self-SHORTCUT_IMAGE'">
                    <img class="avatar" :src="item.avatar" />
                    <div>
                        <b> {{item.grade}} {{item.nickname}} {{item.time}}</b>
                        <img :src="shortcutImage[item.msg].url" />
                    </div>
                </h5>
                <h5 v-if="item.type==='self-SHORTCUT_TEXT'">
                    <img class="avatar" :src="item.avatar" />
                    <div>
                        <b> {{item.grade}} {{item.nickname}} {{item.time}}</b>
                        <span>{{item.msg}}</span>
                    </div>
                </h5>
            </template>
        </hgroup>
        <div :class="{'disabled':chatroomSettings.status!=='1'}">
            <div class="cover" v-if="chatroomSettings.status!=='1'"></div>
            <input type="text" @blur="_blured($event)" @keydown.enter="send()" v-model="message" :placeholder="chatroomSettings.status!=='1'?getWord('chatroom_info6'):getWord('chatroom_info7')" ref="chatInput" />
            <template>
                <i v-if="this.shortcutPanel" class="iconfont icon-jianpan" @click="keyboardSwitch()" />
                <i v-else class="iconfont icon-biaoqing" @click="chatInputFocus()" />                
            </template>
            <button class="ripple" @click="send()">{{ getWord('send') }}</button>
        </div>

        <transition name="slide-fade">
            <div class="shortcut-chat" v-show="shortcutPanel">
                <ul class="type clearfix">
                    <li @click="switchShortcut('text')" :class="{'highlight':shortcutTitle['text'].show}">{{shortcutTitle['text'].title}}</li>
                    <li @click="switchShortcut('image')" :class="{'highlight':shortcutTitle['image'].show}">{{shortcutTitle['image'].title}}</li>
                </ul>
                <div class="inner">
                    <template v-if="shortcutTitle['text'].show">
                        <span v-for="item in shortcutText"  @click="sendShortcut({
                            'type':'text',
                            'value':item.value,
                        })">{{item.value}}</span>
                    </template>
                    <template v-if="shortcutTitle['image'].show">
                        <img v-for="(item,index) in shortcutImage" :src="item.url" @click="sendShortcut({
                            'type':'image',
                            'id':index,
                        })" />
                    </template>
                </div>
            </div>
        </transition>

        <transition-group name="slide-fade">
            <template v-for='(item,index) in chatroomNotices'>
                <aside v-if="item.state && (item.msg.indexOf('taklroom_not_in_opentime')===-1 && !(item.msg instanceof Array))" :key="'aside1'+index">
                    <div class="inner chatroom-settings">
                        <i class="iconfont icon-23 close" @click="closeConditions(item)"></i>
                        <h4>{{ getWord('tips2') }}</h4>
                        <div class="list">
                            <h4>{{ getWord('chatroom_rules') }}</h4>
                            <ol>
                                <li v-if="chatroomSettings.talk_conditions.level.status">{{ getWord('chatroom_rules2',{
                                    '$1':chatroomSettings.talk_conditions.level.label
                                }) }}</li> 
                                <li v-if="chatroomSettings.talk_conditions.resume.status">{{ getWord('chatroom_rules5') }}{{ currencySymbol() }} {{ chatroomSettings.talk_conditions.resume.value }}</li>
                                <li v-if="chatroomSettings.talk_conditions.remainMoney.status">{{chatroomSettings.talk_conditions.remainMoney.label}}</li>
                                <li v-if="chatroomSettings.talk_conditions.todayPayMoney.status">{{chatroomSettings.talk_conditions.todayPayMoney.label}}</li>
                                <li v-if="chatroomSettings.talk_conditions.hierarchy.status">{{chatroomSettings.talk_conditions.hierarchy.label}}</li>
                            </ol>
                        </div>
                    </div>
                </aside>
                <aside v-if="item.state && item.msg instanceof Array" :key="'aside2'+index">
                    <div class="inner chatroom-settings">
                        <i class="iconfont icon-23 close" @click="closeConditions(item)"></i>
                        <h4>{{ getWord('tips2') }}</h4>                    
                        <div class="open">
                            <img :src="require('@@/assets/images/chatroom/bg/open.png')" />
                            <h5>                                
                                <!-- <span><b>{{ getWord('chatroom_rules3') }}: </b>{{ chatroomOpenDate }}</span> -->
                                <template v-for="_item in item.msg">
                                    <b v-if="_item.type==='dict_keyword'">{{ getWord(_item.value) }}</b>
                                    <span v-if="_item.type==='value'">{{ _item.value }}</span>
                                    <br v-if="_item.type==='break'" />
                                </template>
                                <br />
                                <span><b>{{ getWord('chatroom_rules6') }}: </b><br />{{ chatroomOpenTime(0) }}</span>
                                <span v-if="chatroomOpenTime(1)"><b class="hide">{{ getWord('chatroom_rules3') }}: </b>{{ chatroomOpenTime(1) }}</span>
                                <span v-if="chatroomOpenTime(2)"><b class="hide">{{ getWord('chatroom_rules3') }}: </b>{{ chatroomOpenTime(2) }}</span>
                            </h5>
                        </div>
                    </div>
                </aside>
                <aside v-if="item.state && item.msg.indexOf('taklroom_not_in_opentime')>-1" :key="'aside3'+index">
                    <div class="inner chatroom-settings">
                        <i class="iconfont icon-23 close" @click="closeConditions(item)"></i>                    
                        <div class="muted">
                            <img :src="require('@@/assets/images/chatroom/bg/muted.png')" />
                            <h4>{{ getWord('chatroom_rules10') }}</h4>
                            <h5>
                                {{ getWord(item.msg) }}
                            </h5>
                            <button class="submit" @click="closeConditions(item)">{{ getWord('ok2') }}</button>
                        </div>
                    </div>
                </aside>
            </template>
            <aside v-if="chatroomSettings.talk_conditions && aside.nickname" :key="'aside4'">
                <div class="inner chatroom-settings">
                    <i class="iconfont icon-23 close" @click="close('nickname')"></i>
                    <h4>{{ getWord('chatroom_rules8') }}</h4>
                    <div class="form">
                        <label>{{ getWord('nickname') }}</label><input type="text" class="nickname" v-model="nickname" :placeholder="getWord('chatroom_rules4',{
                            '$1':'3',
                            '$2':'10',
                        })" />
                    </div>
                    <div class="buttons clearfix">
                        <button class="cancel" @click="close('nickname')">{{ getWord('cancel') }}</button>
                        <button class="submit" @click="comfirm(nickname)">{{ getWord('ok') }}</button>
                    </div>
                </div>
            </aside>
        </transition-group>

        <transition name="slide-fade">
            <aside v-if="hongbao.state===1">                
                <div class="inner hongbao ready">
                    <i class="iconfont icon-23 close" @click="close()"></i>
                    <div class="pre-open">
                        <h4> {{ getWord('chatroom_rules9') }} </h4>
                        <a @click="openHongbao(hongbao.id,hongbao.item,repeatString)">{{ getWord('pull_down_red_envelope') }}</a>
                    </div>
                </div>
            </aside>
            <aside v-if="hongbao.state===2">                
                <div class="inner hongbao get">
                    <i class="iconfont icon-23 close" @click="close()"></i>
                    <div class="open">
                        <h4> {{ getWord('chatroom_rules9') }} </h4>
                        <h5>
                            <span>{{userInfo.currencySymbol || '￥'}} </span>
                            {{ getAmount() }}
                        </h5>
                        <p>{{ getWord('gotten_red_envelope') }}</p>
                    </div>
                </div>
            </aside>
            <aside v-if="hongbao.state===3">                
                <div class="inner hongbao get error">
                    <i class="iconfont icon-23 close" @click="close()"></i>
                    <div class="off">
                        <h3>{{hongbao.item.error}}</h3>
                    </div>
                </div>
            </aside>
        </transition>

        <!-- <transition name="slide-fade">
            <aside class="room-close" v-if="chatroomSettings.status!=='1'">
                <h3>聊天室已关闭</h3>
            </aside>
        </transition> -->
    </div>
</template>
<style lang='scss' scoped>
@import "@@/assets/style/_variables";
.slide-fade-enter-active {
    transition: all .3s ease;
}

.slide-fade-leave-active {
    transition: all .3s ease;
}

.slide-fade-enter,
.slide-fade-leave-to {
    transform: translateY(100%);
    opacity: 0;
}

#chat-room {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    background-color: #ffffff;
    flex-grow: 1;

    &.template-3 {
        position: fixed;
        top: 0;
        z-index: 11;
        width: 100%;

        hgroup {
            background-color:#F5F5F9;

            .avatar {
                width: .8rem;
                height: .8rem;
                margin-top: .35rem;
            }

            h4 {

                span {
                    background-color: #ffffff;
                }
            }

            h5 {

                b {
                    font-size: .2rem;
                    text-align: right;
                }

                span {
                    padding: .2rem;
                    color: #ffffff;
                    background-color: #FF7B7C;

                    &:before {
                        border-color: transparent #FF7B7C;
                    }
                }
            }
        }

        > div {
            padding: .125rem;
            display: flex;
            justify-content: space-between;
            flex: 0 0 0.6rem;

            input {
                width: 100%;
                border:0;
                margin:0;
                background-color: #F5F5F9;
            }

            button {
                background-color: #FFB400;
                color: #333333;
                font-size: .28rem;
                white-space: nowrap;
                margin-left: .125rem;
            }
        }

        aside {

            .inner {

                &.chatroom-settings {

                    > h4 {
                        background-color: #DB5C54;
                    }

                    button {

                        &.submit {
                            background-color: #DB5C54;
                        }
                    }
                }
            }
        }
    }

    &.template-2 {
        height: 1rem;
        box-shadow: inset 0 1px 2px rgba(0,0,0,.3);

        top: 0;
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        z-index: 11;

        > h4.close {
            border: 0;
            background-color: transparent;
            text-align: center;
            padding-bottom: .15rem;
            background: rgb(140,186,241);
            background: linear-gradient(90deg, rgba(140,186,241,1) 0%, rgba(70,119,227,1) 100%);

            span {
                color: #ffffff;
                font-size: .3rem;
                font-weight: normal;
                margin-top: 0.1rem;
                display: inline-block;
                margin-left: 0.4rem;

                i {
                    display: block;
                    font-style: normal;
                    font-size: .2rem;
                    line-height: .2rem;
                }
            }

            

            .iconfont {
                height: 0.5rem;
                line-height: 0.6rem;
                font-size: .4rem;
                width: 0.5rem;
                border: 0;
                border-radius: 100%;
                display: inline-block;
                text-align: center;
                margin-top: .15rem;
                margin-right: .25rem;
                color: #ffffff;
                float: right;
            }
        }

        > div {
            flex: 0 0 .7rem;
        }
    }

    &.template-1 {
        position: relative;

        aside {

            &.room-close {
                position: absolute;
                top: 0;

                &:before {
                    position: absolute;
                    top: 0;
                }
            }
        }
    }

    hgroup {
        padding: 0 .25rem;
        flex-grow: 1;
        overflow: auto;
        background-color: #f3f3f5;

        .avatar {
            width: .74rem;
            height: .74rem;
        }

        p {
            text-align: center;
            color: #333333;
            font-size: .24rem;
            font-weight: 500;
            background-color: #88C75C;
            width: 70%;
            margin: .25rem auto;
            padding: .125rem 0;
            border-radius: 4px;

            &:before {
                content: "系统消息";
                display: block;
                font-size: .28rem;
                margin-bottom: .125rem;
            }
        }

        time {
            max-width: 50%;
            display: block;
            text-align: center;
            margin: 0 auto;
            padding: .025rem .1rem;
            border-radius: .1rem;
            color: #ffffff;
            background-color: #dddddd;
            clear: both;
            margin-top: .1rem;
        }

        h4,
        h5 {
            margin: .25rem auto;
            display: flex;
            font-weight: 500;
            clear: both;

            b {
                display: block;
                font-weight: 500;
                color: #999999;
                margin-bottom: .1rem;

                &.red {
                    color: #EC2829;
                }
            }

            span {
                font-size: .3rem;
                background-color: #ffffff;
                position: relative;
                padding: .175rem;
                display: block;
                max-width: 4.1rem;
                word-break: break-all;

                &:before {
                    content: "";
                    border: solid;
                    border-color: transparent #ffffff;
                    border-width: 6px 6px 6px 0px;
                    left: -6px;
                    top: 50%;
                    position: absolute;
                    margin-top: -6px;
                }
            }

            img {
                max-width: 3rem;
                max-height: 3rem;
            }
        }

        h4 {
            flex-wrap: wrap;

            &.red {

                div {

                    b {
                        color: #EC2829;
                    }
                }
            }

            div {
                margin-left: .25rem;
                flex:1 1 1rem;

                .hongbao {
                    background-image: url('/static/assets/images/chatroom/bg/hongbao.png');
                    background-size: contain;
                    width: 4rem;
                    height: 1.17rem;
                    display: flex;
                    align-items: center;
                    background-color:transparent;

                    &:before {
                        content: none;
                    }

                    &.repeat-string {

                        i {
                            font-size: .2rem;
                            color: #F46262;
                            position:absolute;
                            left: .3rem;
                            bottom: .1rem;
                            font-style: normal;
                        }
                    }

                    &.open {
                        background-image: url('/static/assets/images/chatroom/bg/hongbao-open.png');
                    }

                    b {
                        padding-left:1.0rem;
                        font-size: .3rem;
                        color: #ffffff;
                        font-weight: normal;
                        height: 1rem;
                        line-height: .5rem;
                        overflow: hidden;
                    }
                }

                span {

                    &.plan {
                        padding:0;

                        label {
                            font-size: .3rem;
                            display: block;
                            border-bottom: 1px solid #EEEEEE;
                            padding: .05rem .175rem;

                        }

                        i {
                            font-style: normal;
                            padding: .175rem;
                            display: inline-block;
                            word-break: keep-all;
                        }
                    }
                }
            }

            ul {
                width: 100%;
                text-align: center;
                list-style: none;

                li {
                    font-size: .2rem;
                    margin:.1rem 0;

                    img {
                        width: .2rem;
                        vertical-align: middle;
                        margin: 0 .1rem;
                    }

                    i {
                        font-style: normal;
                        color: #EC2829; 
                    }
                }
            }
        }

        h5 {
            flex-flow: row-reverse;

            span {

                &:before {
                    border-width: 6px 0px 6px 6px;
                    left: auto;
                    right: -6px;
                }
            }

            div {
                margin-right: .25rem;
            }

            b {
                text-align: right;
            }
        }

        h6 {
            float: left;
            clear: both;
            text-align: center;
            width: 100%;

            img {
                width: 5.2rem;
            }

            span {
                color: #FFC034;
                text-align: center;
                border: 1px solid #FFC034;
                border-radius: 10px;
                font-weight: normal;
                margin: .25rem auto;
                display: inline-block;
                left: 50%;
                position: relative;
                transform: translateX(-50%);
                font-size: .2rem;
                padding: .1rem .25rem;
                float: left;
                clear: both;
            }
        }
    }

    > div {
        padding: .125rem .25rem;
        border-top: 1px solid #f3f3f4;
        display: flex;
        justify-content: space-between;

        &.disabled {
            filter: grayscale(1);
            position:relative;
        }

        .cover {
            position:absolute;
            left:0;
            right:0;
            top:0;
            bottom:0;
            opacity: .01;
            z-index: 1;
        }

        &.shortcut-chat {
            flex-direction: column;
            overflow: auto;
            flex: 0 0 5.5rem;

            .type {
                list-style: none;
                text-align: center;

                li {
                    float:left;
                    width: 50%;
                    font-size: .28rem;
                    padding: .1rem 0;
                    
                    &.highlight {
                        border-bottom: 1px solid #EC2829;
                        color:#EC2829;
                    }
                }
            }   

            .inner {

                span {
                    font-size: .28rem;
                    padding: .1rem .25rem;
                    display: inline-block;
                }

                img {
                    max-width: 1.6rem;
                    max-height: 1.6rem;
                }
            }
        }

        button {
            color: #ffffff;
            font-size: .34rem;
            line-height: .34rem;
            border-radius: 4px;
            padding: .15rem .3rem;
            display: inline-block;
            vertical-align: middle;
            border: 1px solid transparent;
        }

        input {
            line-height: .34rem;
            border: 1px solid #E6E6EA;
            padding: .15rem;
            font-size: .28rem;
            box-sizing: border-box;
            vertical-align: middle;
            flex:1 1 1rem;

            &::placeholder {
                color: #cccccc;
            }
        }

        .iconfont {
            font-size: 0.55rem;
            margin: 0 .1rem;
            transform: translateY(.025rem) translateX(.025rem);
        }

        button {            
            background-color: #3985E1;
        }
    }

    aside {
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 11;

        &.room-close {
            height: auto;
            top: .88rem;
            bottom: 0;

            &:before {
                top:.88rem;
                background-color: rgba(0, 0, 0, 0.7);
            }

            h3 {
                color: #ffffff;
                text-shadow: 0 0 6px rgba(0,0,0,.5);
                font-size: 0.7rem;
                position: relative;
                margin-top: -1rem;
            }
        }

        .inner {
            position: relative;
            width: 75vw;
            border-radius: 5px;
            background-color: #ffffff;
            box-shadow: 0px 1px 10px 0 rgba(0, 0, 0, .2);
            max-height: 70vh;
            overflow: auto;
            display: flex;
            flex-direction: column;

            &.chatroom-settings {
                width: 90vw;

                .close {
                    color: #ffffff;
                    font-size: .36rem;
                }

                > h4 {
                    color: #ffffff;
                    font-size: .36rem;
                    background-color: $TEMPLATE2-theme-color;
                    height: .88rem;
                    padding:0;
                    line-height: .88rem;
                }

                button {
                    width: 49%;
                    float: left;
                    border-radius:.15rem;
                    font-size: .34rem;
                    line-height: .88rem;
                    background-color: #EEEEEE;
                    color: #B0B0B0;
                    border:0;

                    &.submit {
                        float:right;
                        background-color: $TEMPLATE2-theme-color;
                        color: #ffffff;
                    }
                }

                div {

                    &.list {
                        padding: 0rem .5rem .5rem;

                        h4 {
                            color: #B0B0B0;
                            font-size: .28rem;
                            padding: .25rem 0;
                            text-align: left;
                        }

                        ol {
                            list-style-position: inside;
                            font-size: .3rem;
                        }
                    }

                    &.open {
                        text-align: center;
                        padding: .25rem .25rem .5rem;

                        img {
                            width: 2rem;
                            height: 2rem;
                            margin-bottom: .25rem;
                        }

                        h5 {
                            font-size: .3rem;
                            font-weight: normal;
                            text-align: left;

                            span {
                                display: block;

                                b {
                                    font-weight: normal;

                                    &.hide {
                                        visibility: hidden;
                                    }
                                }
                            }
                        }
                    }

                    &.muted {
                        text-align: center;

                        img {
                            width: 3.75rem;
                            margin: .25rem 0;
                        }

                        h4 {
                            font-size: .36rem;
                        }

                        h5 {
                            font-size: .3rem;
                            color: #B0B0B0;
                            font-weight: normal;
                            padding: 0 .25rem;
                        }

                        button {
                            margin: .5rem auto;
                            float: none;
                        }
                    }

                    &.form {
                        padding: .25rem;
                        display: flex;
                        font-size: .3rem;
                        align-items:center;

                        label {
                            padding-right: .25rem;
                        }

                        input {
                            flex-grow: 1;
                            border:1px solid rgba(230,230,230,1);
                            border-radius:4px;
                            font-size: inherit;
                            line-height: .88rem;
                            padding: 0 .25rem;

                            &::placeholder {
                                color: #e6e6e6;
                            }
                        }
                    }

                    &.buttons {
                        padding: .25rem;
                    }
                }
            }

            &.hongbao {
                width: auto;
                overflow: visible;

                &.ready {

                    .close {
                        font-size: .52rem;
                        color: #ffffff;
                        border: 1px solid #ffffff;
                        border-radius: 100%;
                        padding: .1rem .1rem .0rem .1rem;
                        top: auto;
                        bottom: -1rem;
                        right: 50%;
                        transform: translateX(50%);
                    }
                }

                &.get {
                    background-color: #F5634B;
                    color: #FFC972;
                    width: 5rem;
                    height: 5rem;

                    .close { 
                        font-size: .5rem;
                        top: auto;
                        bottom: .25rem;
                        right: 50%;
                        transform: translateX(50%);
                    }
                }

                > div {

                    &.open {
                        text-align: center;

                        h4 {
                            margin: .5rem 0 .25rem;
                        }

                        h5 {
                            font-size: 1rem;
                            font-weight: normal;
                            word-spacing: -0.2rem;
                            font-family: "Helvetica";

                            span {
                                font-size: .36rem;
                            }
                        }
                    }

                    &.off {
                        padding: .66rem .33rem;
                    }

                    &.pre-open {
                        background-image:url('/static/assets/images/chatroom/bg/popup-hongbao.png');
                        width: 6rem;
                        height: 7.9rem;
                        background-size: cover;

                        > h4 {
                            font-size: .34rem;
                            color: #FFC972;
                            padding-top: 1rem;
                        }

                        > a {
                            color: #fff;
                            font-size: .56rem;
                            position: absolute;
                            top: 3.6rem;
                            left: 0;
                            right: 0;
                            width: 3rem;
                            margin: 0 auto;
                            text-align: center;
                        }
                    }
                }
            }
        }

        .close {
            position: absolute;
            right: .25rem;
            top: .25rem;
        }

        h4 {
            text-align: center;
            font-size: .3rem;
            font-weight: 500;
            padding: .25rem;

            span {
                font-size: .24rem;
                display: block;
                color: #999999;
            }
        }

        .list {
            padding:.25rem;
            padding-top: 0;
        }

        ol {
            list-style-position: inside;

            li {
                
            }
        }

        &:before {
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            position: fixed;
            background-color: rgba(0, 0, 0, .2);
            content: "";
            cursor: default;
        }
    }
}
</style>